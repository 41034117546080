globalThis.ION_SDK_VERSION = "1.125";
export { default as SphericalPolygon } from './Source/Core/SphericalPolygon.js';
export { default as initializeSensors } from './Source/Core/initializeSensors.js';
export { default as ConicSensorGraphics } from './Source/DataSources/ConicSensorGraphics.js';
export { default as ConicSensorVisualizer } from './Source/DataSources/ConicSensorVisualizer.js';
export { default as CustomPatternSensorGraphics } from './Source/DataSources/CustomPatternSensorGraphics.js';
export { default as CustomPatternSensorVisualizer } from './Source/DataSources/CustomPatternSensorVisualizer.js';
export { default as RectangularSensorGraphics } from './Source/DataSources/RectangularSensorGraphics.js';
export { default as RectangularSensorVisualizer } from './Source/DataSources/RectangularSensorVisualizer.js';
export { default as processCommonSensorProperties } from './Source/DataSources/processCommonSensorProperties.js';
export { default as processConicSensor } from './Source/DataSources/processConicSensor.js';
export { default as processCustomPatternSensor } from './Source/DataSources/processCustomPatternSensor.js';
export { default as processRectangularSensor } from './Source/DataSources/processRectangularSensor.js';
export { default as ConicSensor } from './Source/Scene/ConicSensor.js';
export { default as CustomPatternSensor } from './Source/Scene/CustomPatternSensor.js';
export { default as RectangularSensor } from './Source/Scene/RectangularSensor.js';
export { default as SensorVolume } from './Source/Scene/SensorVolume.js';
export { default as SphericalPolygonShaderSupport } from './Source/Scene/SphericalPolygonShaderSupport.js';
export { default as _shadersConicSensorInsideFS } from './Source/Shaders/ConicSensorInsideFS.js';
export { default as _shadersConicSensorOutsideFS } from './Source/Shaders/ConicSensorOutsideFS.js';
export { default as _shadersEllipsoidHorizonFacetFS } from './Source/Shaders/EllipsoidHorizonFacetFS.js';
export { default as _shadersEllipsoidHorizonFacetInsideFS } from './Source/Shaders/EllipsoidHorizonFacetInsideFS.js';
export { default as _shadersEllipsoidHorizonFacetOutsideFS } from './Source/Shaders/EllipsoidHorizonFacetOutsideFS.js';
export { default as _shadersInfiniteCone } from './Source/Shaders/InfiniteCone.js';
export { default as _shadersPlanarSensorVolumeFS } from './Source/Shaders/PlanarSensorVolumeFS.js';
export { default as _shadersPlanarSensorVolumeVS } from './Source/Shaders/PlanarSensorVolumeVS.js';
export { default as _shadersSensorDomeFS } from './Source/Shaders/SensorDomeFS.js';
export { default as _shadersSensorDomeInsideFS } from './Source/Shaders/SensorDomeInsideFS.js';
export { default as _shadersSensorDomeOutsideFS } from './Source/Shaders/SensorDomeOutsideFS.js';
export { default as _shadersSensorSurfaceFS } from './Source/Shaders/SensorSurfaceFS.js';
export { default as _shadersSensorVolume } from './Source/Shaders/SensorVolume.js';
export { default as _shadersSensorVolume2DFS } from './Source/Shaders/SensorVolume2DFS.js';
export { default as _shadersSensorVolume2DVS } from './Source/Shaders/SensorVolume2DVS.js';
export { default as _shadersSensorVolume3DVS } from './Source/Shaders/SensorVolume3DVS.js';
export { default as _shadersSensorVolumeDepth } from './Source/Shaders/SensorVolumeDepth.js';
export { default as _shadersisZeroMatrix } from './Source/Shaders/isZeroMatrix.js';
